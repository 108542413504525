import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import DrivosityCertified from "../components/OurCompany/DrivosityCertified/DrivosityCertified";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/drivosity-certified.jpg"

const DrivosityCertifiedPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Drivosity Certified - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="DrivosityCertified honors first-party delivery drivers and franchise businesses committed to road safety. Our certification system measures safe driving habits in real time through GPS tracking and reports. Explore how you can be part of the solution for safer roads."/>

                <meta property="og:title" content="Drivosity Certified - Drivosity"/>
                <meta property="og:description" content="DrivosityCertified honors first-party delivery drivers and franchise businesses committed to road safety. Our certification system measures safe driving habits in real time through GPS tracking and reports. Explore how you can be part of the solution for safer roads."/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>  
                <meta property="og:image:alt" content="Diverse business professionals giving a high-five, celebrating teamwork, success, and Drivosity-certified drivers."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/drivosity-certified`}/>
            </Helmet>
            <Layout component={<DrivosityCertified/>}/>
        </>
    )
}

export default DrivosityCertifiedPage;
